<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
<div class="tou">
  <div class="top-wrapper">
    <ButtonClose @click.native="$router.go(-1)" />
  </div>
  <div class="content-wrapper">
    <h1 class="big-text">Nutzungsbedingungen</h1>
    
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora fuga dolorum voluptates laborum exercitationem nesciunt vitae saepe nihil quidem laboriosam odit deleniti pariatur sit corporis perspiciatis quae quo, impedit animi!</p>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora fuga dolorum voluptates laborum exercitationem nesciunt vitae saepe nihil quidem laboriosam odit deleniti pariatur sit corporis perspiciatis quae quo, impedit animi!</p>
    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora fuga dolorum voluptates laborum exercitationem nesciunt vitae saepe nihil quidem laboriosam odit deleniti pariatur sit corporis perspiciatis quae quo, impedit animi!</p>
    
  </div>
</div>
</template>

<script>
import ButtonClose from '@/components/ButtonClose.vue'

export default {
  name: 'Privacy',
  components: {
    ButtonClose
  },
  data() {
    return {
      state: this.$store.state,

    }
  },
  computed: {

  },
  mounted() {
    
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.tou 
  background-color: #fff
  height: 100dvh;
  
.top-wrapper
  padding 6.4vw 6.4vw 0
  margin-bottom 4.3vw
  display: flex;
  justify-content: flex-end;


.content-wrapper
  padding: 6.4vw;
  text-align: left

  h1
    text-align: left;
    color: #0D0E21
  
  p
    font-family "Hind"
    text-align: left;
    // margin: 0;
    color: #0D0E21;

  .big-text
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 700;
    margin-bottom: 16px;
    
    &:not(:first-child)
      margin-top: 76px;
  
  .main-text
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;

  .sub-text
    font-size: 16px;
    line-height: 22.5px;
    font-weight: 300;

</style>